<template>
  <div class="flex haw-home" v-if="authUserReady">
    <Header></Header>
    <SidebarLeft></SidebarLeft>

    <MainContent class="pt-8">
      <slot />
      <router-view v-if="!$slots.default" />
    </MainContent>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { useStore } from "vuex";
import SidebarLeft from "./partials/SidebarLeft.vue";
import Header from "./partials/Header.vue";
import MainContent from "../../../../haw-components-hac/Shared/HAWHome/partials/MainContent.vue";
import { ref } from "@vue/reactivity";

export default {
  name: "CourseLayout",

  props: {
    public: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    SidebarLeft,
    MainContent,
    Header,
  },

  setup(props) {
    const store = useStore();

    const user = store.getters["usm/authUser/user"];
    const authUserReady = ref(!!user);

    if (!authUserReady.value) {
      const { docs } = useApi({
        module: "usm",
        method: "showAuthUser",
        onMount: !props.public,
        onSuccess: async () => {
          await store.dispatch("usm/authUser/saveData", docs.value);
          authUserReady.value = true;
        },
      });
    }

    return { authUserReady };
  },
};
</script>
<style lang="scss" scoped>
.haw-home {
  background-color: #ffffff;
}
</style>
