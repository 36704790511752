<template>
  <BaseFrontdoor>
    <CourseLayout />
  </BaseFrontdoor>
</template>

<script>
import CourseLayout from "./Layout";

export default {
  name: "acm",

  components: {
    CourseLayout,
  },
};
</script>
