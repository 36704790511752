<template>
  <div class="main-content min-h-screen flex-grow-1 flex" :class="{ 'pr-4': paddingRight }">
    <div class="main-content__inner py-4 px-6 flex-grow-1 flex min-h-full">
      <div class="block w-full">
        <div class="mainContent__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  props: {
    paddingRight: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  background-color: rgba(76, 187, 199, 0.04);
  &__inner {
    background-color: transparent;
  }
}
</style>
