<template>
  <BaseButton
    label="acm.courses.update.label.submit.label"
    @click="confirmDialog"
    :loading="isLoading"
  />
</template>

<script>
import useApi from "@use/useApi";
import useConfirmDialog from "@use/useConfirmDialog";
import useNotifications from "@use/useNotifications";

export default {
  props: {
    courseRequestId: {
      type: undefined,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { showError, showSuccess } = useNotifications();

    const { error, isLoading, callApi, backendValidation } = useApi({
      module: "acm",
      method: "publishCourseRequest",
      params: {
        courseRequestId: props.courseRequestId,
      },
      onSuccess: () => {
        showSuccess(
          "acm.courses.update.label.submit.notification.success.header",
          "acm.courses.update.label.submit.notification.success.message"
        );
        emit("submitted");
      },
      onError: () => {
        showError(
          "acm.courses.update.label.submit.notification.error.header",
          `acm.backendValidation.courseRequest.${error.value.response.data.message}`
        );
      },
    });

    const { confirmDialog } = useConfirmDialog({
      header: "acm.courses.update.label.submit.confirm.header",
      message: "acm.courses.update.label.submit.confirm.message",
      onAccept: async () => {
        await callApi({});
      },
    });

    return {
      backendValidation,
      confirmDialog,
      isLoading,
    };
  },
};
</script>
