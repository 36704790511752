<template>
  <BaseHeaderBar fixed>
    <template #left>
      <BaseButton class="mr-3" @click="goBack" icon="pi pi-angle-left" />
      <BaseDeleteData
        update-module="acm"
        update-method="deleteCourseRequest"
        :formData="{ courseRequestId }"
        icon="pi pi-trash"
        deleteDialogMessage="acm.courses.delete.dialog.message"
        deleteDialogHeader="acm.courses.delete.dialog.header"
        outlined
        :onDeleted="goBack"
      />
    </template>
    <template #center>
      <BaseText class="font-bold">{{ headerTitle }}</BaseText>
    </template>
    <template #right>
      <SubmitCourseButton @submitted="goBack" :course-request-id="courseRequestId" />
    </template>
  </BaseHeaderBar>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import SubmitCourseButton from "./SubmitCourseButton";

export default {
  name: "Header",
  components: { SubmitCourseButton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { courseRequestId } = route.params;

    const headerTitle = ref(null);

    const { docs } = useApi({
      module: "acm",
      method: "showCourseRequest",
      onMount: true,
      params: { courseRequestId: parseInt(courseRequestId, 10) },
      onSuccess: () => {
        headerTitle.value = docs.value.data.title;
      },
    });

    const goBack = () => {
      router.push({ name: "CourseRequestsList" });
    };

    return {
      headerTitle,
      goBack,
      courseRequestId,
    };
  },
};
</script>
