<template>
  <div class="course-request-editor-sidebar-left flex w-18rem text-white sidebar--left px-3 pt-8">
    <div class="block w-18rem">
      <div class="my-4">
        <BaseCard class="mb-6 cursor-pointer" @click="showCourseInfo">
          <BaseText
            class="font-bold course-information p-3"
            :class="{ 'course-information--active text-primary': currentRoute === 'CourseUpdate' }"
          >
            {{ $t("acm.courses.update.label.courseInfo") }}
          </BaseText>
        </BaseCard>
        <BaseCard class="chapters">
          <div v-if="hasDocs" class="chapters__container">
            <BaseRow
              class="chapter chapter__information p-3 mb-5"
              :class="{ 'chapter__information--active text-primary': activeChapter === chapter.id }"
              v-for="chapter in chapters"
              :key="chapter.id"
              no-gutter
              @click="showChapter(chapter.id)"
            >
              <BaseCol cols="11" class="cursor-pointer">
                <BaseText :class="{ 'text-primary': activeChapter === chapter.id }">
                  {{ `${$t("acm.chapters.label.chapter")} ${chapter.order}` }}
                </BaseText>
                <br />
                <BaseText
                  class="font-bold"
                  :class="{ 'text-primary': activeChapter === chapter.id }"
                >
                  {{ chapter.displayName }}
                </BaseText>
              </BaseCol>
              <BaseCol cols="1" class="align-self-start">
                <BaseDeleteData
                  update-module="acm"
                  update-method="deleteCourseRequestChapter"
                  :formData="{ courseRequestChapterId: chapter.id }"
                  icon="pi pi-trash"
                  deleteDialogMessage="acm.chapters.delete.dialog.message"
                  deleteDialogHeader="acm.chapters.delete.dialog.header"
                  iconOnly
                  :onDeleted="onDeleted"
                />
              </BaseCol>
              <BaseCol cols="12" class="mt-3">
                <BaseTag
                  fullWidth
                  :severity="chapter.courseRequestChapterStatus.severity"
                  padding="2"
                >
                  {{ chapter.courseRequestChapterStatus.displayName }}
                </BaseTag>
              </BaseCol>
            </BaseRow>
          </div>
          <template #footer>
            <BaseButton label="acm.chapters.label.add" @click="addChapter" />
          </template>
        </BaseCard>
      </div>

      <div class="my-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useApi from "@use/useApi";

export default {
  name: "SidebarLeft",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { courseRequestId, courseRequestChapterId } = route.params;
    const chapters = ref();
    const currentRoute = ref(route.name);
    const activeChapter = ref(parseInt(route.params.courseRequestChapterId, 10));

    const { docs, hasDocs, callApi } = useApi({
      module: "acm",
      method: "listCourseRequestChapters",
      onMount: true,
      params: { courseRequestId },
      onSuccess: () => {
        chapters.value = docs.value.data;
      },
    });

    const reloadChapterList = computed(() => store.getters["acm/chapters/reloadChapterList"]);

    const addChapter = () => {
      router.push({ name: "AddChapter" });
    };

    const showChapter = (id) => {
      router.push({ name: "ViewChapter", params: { courseRequestChapterId: id } });
    };

    const showCourseInfo = () => {
      router.push({ name: "CourseUpdate" });
    };

    const onDeleted = async () => {
      await callApi();
      await router.push({ name: "CourseUpdate" });
    };

    watch(route, () => {
      currentRoute.value = route.name;
      activeChapter.value = parseInt(route.params.courseRequestChapterId, 10);
    });

    watch(reloadChapterList, (newVal) => {
      if (newVal) {
        callApi();
        store.dispatch("acm/chapters/reloadChapterList", false);
      }
    });

    return {
      courseRequestId,
      courseRequestChapterId,
      showCourseInfo,
      addChapter,
      showChapter,
      hasDocs,
      chapters,
      currentRoute,
      activeChapter,
      onDeleted,
      callApi,
    };
  },
};
</script>

<style lang="scss">
.course-request-editor-sidebar-left {
  &.sidebar--left {
    background-color: var(--gc-main-background-color);
  }

  .position-fixed {
    position: fixed !important;
  }

  .course-information {
    display: block;
    background-color: var(--haw-color-item-inactive);
    margin: -1rem;

    &--active {
      background-color: var(--haw-color-item-active);
    }
  }

  .chapters {
    .p-card {
      &-body {
        padding: 1rem 0;
      }

      &-content {
        min-height: calc(65vh - 5rem);
      }

      &-footer {
        padding: 1rem 1rem 0;
        background: white;
        text-align: center;
      }
    }
  }

  .chapter {
    &__information {
      background-color: var(--haw-color-item-inactive);
      margin-top: -1rem;
      word-break: break-word;

      &--active {
        background-color: var(--haw-color-item-active);
      }
    }
  }
}
</style>
